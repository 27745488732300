import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import Button from '@mui/material/Button';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../../theme/colors';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';

const infoWrapper = {
    padding: '40px 136px 0px',
    maxWidth: '1440px',
    width: '100%',
};
const sizesWrapper = {
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    height: '100%',
    minHeight: '378px',
    borderRadius: '10px',
    padding: '41px 67px 57px 48px',
};
const sizesContainer = {
    minHeight: '205px',
};
const individualSizeContainer = {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
};
const customInfoTitleText = {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    fontSize: '22px',
    lineHeight: '26,82px',
};
const osWrapper = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '378px',
    padding: '41px 39px 34px 49px',
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
};

const osElementFlexCentered = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
};

const serviceIntroductionData = {
    title: 'Nubi Compute Service',
    firstParagraph: 'Servicio de máquinas virtuales de alta performance en la nube.',
    secondParagraph: 'Creá y corré las aplicaciones de tu negocio en un ambiente siempre disponible.',
    gatsbyImg: (<StaticImage src="../../images/computer_service_282.svg" alt="Computadora" />),
    isButton: true,
    externalLinkButton: (
        <a
            href="https://portal.nubi2go.com/index.php?rp=/store/nubi-compute-service"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                aria-label="Crear ahora"
                style={{ width: '100%', height: '100%' }}
            >
                Crear ahora
            </Button>
        </a>),
};

const casesOfUseDataFirstRow = {
    title: 'Funcionalidades del servicio',
    cases: [
        {
            image: (<StaticImage src="../../images/access-console_icon.svg" alt="Acceso a la consola" />),
            text: 'Acceso a la consola',
        },
        {
            image: (<StaticImage src="../../images/snapshots.svg" alt="Snapshot" />),
            text: 'Crear y restaurar Snapshots',
        },
        {
            image: (<StaticImage src="../../images/console_icon.svg" alt="Ver los Logs" />),
            text: 'Ver los Logs',
        },
        {
            image: (<StaticImage src="../../images/icono_www.svg" alt="Asignar IP pública" />),
            text: 'Asignar IP pública',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 3,
    customPadding: '40px 100px 0px',
    maxWidth: { maxWidth: '1084px' },
    customContainerPadding: '48px 24px 0px',
};
const casesOfUseDataSecondRow = {
    cases: [
        {
            image: (<StaticImage src="../../images/volume_expansion.svg" alt="Resize de la máquina virtual" />),
            text: 'Resize de la máquina virtual',
        },
        {
            image: (<StaticImage src="../../images/firewall_icon.svg" alt="Firewall" />),
            text: 'Reglas de grupo de seguridad',
        },
        {
            image: (<LayersOutlinedIcon style={{ color: colors.green, height: '107px', width: '107px' }} />),
            text: 'Agregar volúmenes',
            subTitleLink: '(Ver Flexible Block Storage)',
            subTitleLinkUrl: '/services/flexible_block_storage',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 4,
    customPadding: '20px 100px 33px',
    maxWidth: { maxWidth: '792px' },
    customContainerPadding: '0px 24px 48px',
};

const NubiComputerService = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseDataFirstRow} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseDataSecondRow} />
        <Box
            style={{
                backgroundColor: colors.darkViolet,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '20px 24px' }, ...infoWrapper })}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{ ...sizesWrapper }}
                    >
                        <Box>
                            <Typography mb={5} style={{ ...customInfoTitleText }}>
                                Tamaños
                            </Typography>
                        </Box>
                        <Grid container sx={{ ...sizesContainer }}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                pr={2}
                                sx={(theme) => ({ [theme.breakpoints.down('lg')]: { padding: '0px 0px 20px 0px' }, ...individualSizeContainer })}
                            >
                                <Box style={{ width: '86px' }} mb={3}>
                                    <StaticImage src="../../images/service_predefined.svg" alt="Predefinidos" />
                                </Box>
                                <Box>
                                    <Typography variant="h4" mb={1} style={{ lineHeight: '24px' }}>
                                        Predefinidos
                                    </Typography>
                                    <Typography variant="h5" style={{ lineHeight: '25px' }}>
                                        Configuraciones prearmadas para agilizar tu despliegue.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6} pr={2} sx={{ ...individualSizeContainer }}>
                                <Box style={{ width: '86px' }} mb={3}>
                                    <StaticImage src="../../images/service_adapted.svg" alt="Personalizados" />
                                </Box>
                                <Box>
                                    <Typography variant="h4" mb={1} style={{ lineHeight: '24px' }}>
                                        Personalizados
                                    </Typography>
                                    <Typography variant="h5" style={{ lineHeight: '25px' }}>
                                        Si ninguna se adapta a tus necesidades, configurá los recursos a medida.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}
                        pl={5}
                        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { paddingLeft: '0px', paddingTop: '40px' } })}
                    >
                        <Box sx={{ ...osWrapper }}>
                            <Box>
                                <Typography mb={4} style={{ ...customInfoTitleText, textAlign: 'center' }}>
                                    Sistemas Operativos
                                </Typography>
                            </Box>
                            <Grid container style={{ minHeight: '205px', height: '100%' }}>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ ...osElementFlexCentered, minHeight: '205px', justifyContent: 'space-between' }}
                                >
                                    <Box sx={{ ...osElementFlexCentered, justifyContent: 'flex-start' }}>
                                        <StaticImage src="../../images/ubuntu_icon.svg" alt="Ubuntu" />
                                        <Typography variant="h6">
                                            Ubuntu
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ...osElementFlexCentered, justifyContent: 'flex-start' }}>
                                        <StaticImage src="../../images/debian_icon.svg" alt="Debian" />
                                        <Typography variant="h6">
                                            Debian
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{ ...osElementFlexCentered, minHeight: '205px', justifyContent: 'space-between' }}
                                >
                                    <Box sx={{ ...osElementFlexCentered, justifyContent: 'flex-start' }}>
                                        <Box height="54px" mb={1}>
                                            <StaticImage src="../../images/centos_icon.svg" alt="CentOS" />
                                        </Box>
                                        <Typography variant="h6">
                                            CentOS
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ...osElementFlexCentered, justifyContent: 'flex-start' }}>
                                        <Box height="54px" mb={1}>
                                            <StaticImage src="../../images/windows_icon.svg" alt="Windows Server" />
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                lineHeight: '19.5px',
                                                width: '100px',
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            Windows Server
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default NubiComputerService;
